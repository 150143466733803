.container {
  height: 100vh;
  width: 100vw;
}
.body {
  background-color: #051336;
  padding: 10vh 10vw;
  display: flex;
  justify-content: space-around;
  overflow-y: auto;
  min-height: 100vh;
}

.img {
  position: fixed;
  max-width: 50vw;
  max-height: 50vh;
  padding: 5vh 0 5vh 0;
}
.text {
  color: #fff;
  width: 40vw;
  line-height: 1.5;
}
.textUnderForm {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 8rem;
  color: #fff;
  font-size: 18px;
  line-height: 143%;
  letter-spacing: 0.018em;
}
.textUnderFormSignup {
  @extend .textUnderForm;
  margin-top: -3rem;
  margin-bottom: 0.5rem;
}
.link {
  color: #25C955 !important;
  font-weight: 700;
}
.link:hover {
  text-decoration: underline;
}
.logos {
  padding: 4rem 0 0 0;
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
@media (max-width: 1025px) and (orientation: portrait) {
  .body {
    display: flex;
    justify-content: center;
  }
  .text,
  .img,
  .logos {
    display: none;
  }
  .textUnderForm {
    text-align: center;
    margin-left: 0;
  }
}
@media (max-width: 600px) {
  .body {
    display: flex;
    justify-content: center;
    overflow-y: auto;
  }
  .textUnderForm {
    padding-bottom: 30px;
  }
}
